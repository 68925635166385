import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  minPrice: Infinity,
  maxPrice: -Infinity,
  fixedMinPrice: Infinity,
};

const itineraryPriceSlice = createSlice({
  name: "itineraryPrice",
  initialState,
  reducers: {
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setFixedMinPrice: (state, action) => {
      state.fixedMinPrice = action.payload;
    },
  },
});

export const { setMinPrice, setMaxPrice, setFixedMinPrice } =
  itineraryPriceSlice.actions;

export default itineraryPriceSlice.reducer;
