"use client";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { callApiRequest } from "../../../redux/itinerarySlice";
import { useRouter } from "next/navigation";

import {
  setDeparture,
  setDepartureCode,
  setArrival,
  setArrivalCode,
  setDate,
  setReturnDate,
} from "@/redux/itinerarySlice";
import globalAxiosURL from "@/hooks/globalAxiosURL";
const Page = () => {
  const [suggestions, setSuggestions] = useState([]);
  const [arrivalSuggestions, setArrivalSuggestions] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [loadingArrivalSuggestions, setLoadingArrivalSuggestions] =
    useState(false);
  const [activeInput, setActiveInput] = useState(null);
  const axiosPublic = globalAxiosURL()

  const router = useRouter();
  const dispatch = useDispatch();

  const {
    departure,
    departureCode,
    arrival,
    arrivalCode,
    date,
    returnDate,
    travelerCounts,
  } = useSelector((state) => state.itineraries);

  const [search, setSearch] = useState(false);

  const fetchSuggestions = async (query, setSuggestions, setLoading) => {
    setLoading(true);
    try {
      const response = await axiosPublic.get(
        `/airportDetails?search=${query}`
      );
      setSuggestions(response.data.data || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounce = (fn, delay) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => fn(...args), delay);
    };
  };

  const handleDepartureChange = debounce((value) => {
    fetchSuggestions(value, setSuggestions, setLoadingSuggestions);
  }, 300);

  const handleArrivalChange = debounce((value) => {
    fetchSuggestions(
      value,
      setArrivalSuggestions,
      setLoadingArrivalSuggestions
    );
  }, 300);

  const handleBlur = () => {
    setActiveInput(null);
    setSuggestions([]);
    setArrivalSuggestions([]);
  };

  // Function to handle auto-fetching on input focus if input is empty
  const handleFocus = (inputType) => {
    setActiveInput(inputType);
    if (inputType === "departure") {
      fetchSuggestions(departure, setSuggestions, setLoadingSuggestions); // Fetch for departure if empty
    } else if (inputType === "arrival") {
      fetchSuggestions(
        arrival,
        setArrivalSuggestions,
        setLoadingArrivalSuggestions
      ); // Fetch for arrival if empty
    }
  };

  const handleForm = (e) => {
    e.preventDefault();

    setSearch(true);
    const convertTravelerCounts = (data) => {
      const typeMapping = {
        adults: "ADT",
        children: "CHD",
        kids: "KID",
        infants: "INF",
      };

      return Object.entries(data)
        .filter(([_, value]) => value !== 0)
        .map(([key, value]) => ({
          Code: typeMapping[key],
          Quantity: value,
        }));
    };
    const formData = {
      date: date,
      departureAirports: departureCode,
      arrivalAirports: arrivalCode,
      returnDate: returnDate,
      passengerTypes: convertTravelerCounts(travelerCounts),
    };
    // console.log(formData);
    const query = new URLSearchParams({
      departure: departureCode,
      arrival: arrivalCode,
      date: date,
      returnDate: returnDate,
    });
    // Add non-zero traveler counts to the query
    Object.entries(travelerCounts)
      .filter(([_, value]) => value !== 0)
      .forEach(([key, value]) => {
        query.set(key, value);
      });
    // dispatch(callApiRequest(formData));
    router.push(`/flights/results?${query}`);
    setSearch(false);
  };

  return (
    <div className="py-1 text-black">
      <div className="py-1 rounded">
        <form
          onSubmit={handleForm}
          className="flex flex-col lg:flex-row lg:gap-6 gap-3 mb-4 relative"
        >
          {/* Departure Input */}
          <div className="flex-1 relative mb-4 sm:mb-0">
            <input
              type="text"
              className="mr-2 w-full p-2 border border-gray-300 text-sm rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
              placeholder="Departure"
              value={departure}
              onChange={(e) => {
                dispatch(setDeparture(e.target.value));
                handleDepartureChange(e.target.value);
              }}
              onFocus={() => handleFocus("departure")} // Trigger fetch on focus if empty
              onBlur={handleBlur}
            />
            {activeInput === "departure" && (
              <ul className="absolute text-xs w-full md:w-[300px] left-0 right-0 bg-white border border-gray-300 rounded mt-1 z-10">
                {loadingSuggestions ? (
                  <li className="p-2">Loading suggestions...</li>
                ) : (
                  suggestions.map((item, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onMouseDown={() => {
                        dispatch(setDeparture(item.airportName));
                        dispatch(setDepartureCode(item.airportCode));
                      }}
                    >
                      {item.airportName}
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>

          {/* Arrival Input */}
          <div className="flex-1 relative mb-4 sm:mb-0">
            <input
              type="text"
              className="mr-2 w-full p-2 border border-gray-300 rounded text-sm focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
              placeholder="Arrival"
              value={arrival}
              onChange={(e) => {
                dispatch(setArrival(e.target.value));
                handleArrivalChange(e.target.value);
              }}
              onFocus={() => handleFocus("arrival")} // Trigger fetch on focus if empty
              onBlur={handleBlur}
            />
            {activeInput === "arrival" && (
              <ul className="absolute text-xs w-full md:w-[300px] left-0 right-0 bg-white border border-gray-300 rounded mt-1 z-10">
                {loadingArrivalSuggestions ? (
                  <li className="p-2">Loading suggestions...</li>
                ) : (
                  arrivalSuggestions.map((item, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onMouseDown={() => {
                        dispatch(setArrival(item.airportName));
                        dispatch(setArrivalCode(item.airportCode));
                      }}
                    >
                      {item.airportName}
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>

          {/* Departure Date Input */}
          <div className="flex-1 relative mb-4 sm:mb-0">
            <input
              type="date"
              className="mr-2 w-full p-2 !text-sm border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
              placeholder="Departure date"
              value={date}
              onChange={(e) => dispatch(setDate(e.target.value))}
              onClick={(e) => e.target.showPicker()}
            />
          </div>

          {/* Return Date Input */}
          <div className="flex-1 relative mb-4 sm:mb-0">
            <input
              type="date"
              className="mr-2 w-full p-2 !text-sm border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
              placeholder="Return date"
              value={returnDate}
              onChange={(e) => dispatch(setReturnDate(e.target.value))}
              onClick={(e) => e.target.showPicker()}
            />
          </div>

          {/* Search Button */}
          <div className="flex justify-center lg:justify-start w-full lg:w-auto mt-4 lg:mt-0">
            <button
              className="bg-[#00703E] hover:bg-[#185f3f] text-[15px] duration-300 w-[150px] h-[50px] text-white rounded"
              type="submit"
            >
              {search ? "Searching..." : "Search Flights"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Page;
