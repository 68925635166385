import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CardSkeleton = () => {
  return (
    <div className="rounded-lg overflow-hidden">
      <Skeleton height={230} />

      <div className="pt-4 pb-5">
        <Skeleton height={24} />
        <div className="w-[50%]">
          <Skeleton height={24} className="my-3" />
        </div>
        <div className="w-[40%] mt-1 rounded-full overflow-hidden">
          <Skeleton height={27} />
        </div>
      </div>
    </div>
  );
};

export default CardSkeleton;
