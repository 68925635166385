import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  revalidateData: null,
};

const revalidateDataSlice = createSlice({
  name: "revalidateData",
  initialState,
  reducers: {
    setRevalidateData: (state, action) => {
      state.revalidateData = action.payload;
    },
    resetRevalidateData: (state) => {
      state.revalidateData = initialState.revalidateData;
    },
  },
});

export const { setRevalidateData, resetRevalidateData } =
  revalidateDataSlice.actions;

export default revalidateDataSlice.reducer;
