"use client";

import { useEffect } from "react";
import Head from "next/head";
import Script from "next/script";

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

type AdsConfig = {
  client: string;
  src: string;
  slot: string;
  format?: string;
  responsive?: boolean;
};

type HeadProps = {
  title?: string;
  description?: string;
  canonicalUrl?: string;
  schema?: object;
  adsConfig?: AdsConfig;
};

export default function CustomHead({
  title = "HAMS FLY",
  description = "The signature of excellence.",
  canonicalUrl,
  schema,
  adsConfig,
}: HeadProps) {
  useEffect(() => {
    if (typeof window !== "undefined" && window.adsbygoogle) {
      try {
        window.adsbygoogle.push({});
      } catch (error) {
        console.error("AdSense initialization failed:", error);
      }
    }
  }, [adsConfig]);

  return (
    <>
      <Head>
        {/* SEO Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

        {/* Schema.org Structured Data */}
        {schema && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
          />
        )}
      </Head>

      {/* AdSense Script */}
      {adsConfig && (
        <Script
          async
          src={`${adsConfig.src}?client=${adsConfig.client}`}
          crossOrigin="anonymous"
          strategy="afterInteractive"
          onLoad={() => {
            if (window.adsbygoogle) {
              window.adsbygoogle.push({});
            }
          }}
        />
      )}

      {/* AdSense Ad */}
      {adsConfig && (
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={adsConfig.client}
          data-ad-slot={adsConfig.slot}
          data-ad-format={adsConfig.format || "auto"}
          data-full-width-responsive={adsConfig.responsive ? "true" : "false"}
        ></ins>
      )}
    </>
  );
}
