import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedItinerary: null,
  selectedItineraryData: null,
};

const selectedItinerarySlice = createSlice({
  name: "selectItinerary",
  initialState,
  reducers: {
    setSelectedItinerary: (state, action) => {
      state.selectedItinerary = action.payload;
    },
    setSelectedItineraryData: (state, action) => {
      state.selectedItineraryData = action.payload;
    },
  },
});

export const { setSelectedItinerary, setSelectedItineraryData } =
  selectedItinerarySlice.actions;

export default selectedItinerarySlice.reducer;
