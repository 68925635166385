import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flight: [],
};

const flightsSlice = createSlice({
  name: "flight",
  initialState,
  reducers: {
    setFlight: (state, action) => {
      state.flight = action.payload;
    },
    reset: (state) => {
      state.flight = initialState.flight;
    },
  },
});

export const { setFlight, reset } = flightsSlice.actions;

export default flightsSlice.reducer;
