"use client";
import React, { useEffect, useState } from "react";
import Feedback from "@/components/homeComponent/feedback/Feedback";
import globalAxiosURL from "@/hooks/globalAxiosURL";

const FeedbackSection = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const axiosURL = globalAxiosURL();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await axiosURL.get("/feedbacks");
        setFeedbacks(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // fetchFeedbacks();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div data-aos="fade-up">
      <div className="max-w-[1200px] mx-auto px-4 mb-10">
        <div className="text-center mb-1">
          <h2 className="text-3xl p-1">Feedback</h2>
          <p className="text-xl">See what our clients say about us.</p>
        </div>
        <div className="pt-10">
          <Feedback feedbackData={feedbacks} />
        </div>
      </div>
    </div>
  );
};

export default FeedbackSection;
