import axios from "axios";

const URL = axios.create({
  baseURL: "https://api.hamsfly.com/api",
  // baseURL: "http://localhost:3002/api",
});

const globalAxiosURL = () => {
  return URL;
};

export default globalAxiosURL;

// https://api.hamsfly.com/api
// http://localhost:3002/api
