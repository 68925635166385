"use client";

import { useState } from "react";

const Multicity = () => {
  const [searchBox, setSearchBox] = useState(2);

  const [departure, setDeparture] = useState(null);
  const [arrival, setArrival] = useState(null);
  const [date, setDate] = useState(null);

  const handleForm = (e) => {
    e.preventDefault();
    // Your form submission logic here
    console.log(departure, arrival, date);
  };

  return (
    <div className="py-1 text-black">
      <form onSubmit={handleForm} className="rounded hidden md:block py-1">
        {Array(searchBox)
          .fill()
          .map((box, i) => (
            <div key={i} className="flex flex-wrap mb-4 relative gap-x-1">
              <div className="flex-1 relative mb-0 sm:mb-0">
                <input
                  type="text"
                  required
                  className="mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none text-sm focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                  placeholder="Departure"
                  value={departure}
                  onChange={(e) => setDeparture(e.target.value)}
                />
              </div>
              <div className="flex-1 relative mb-2sm:mb-0">
                <input
                  type="text"
                  required
                  className="mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none text-sm focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                  placeholder="Arrival"
                  value={arrival}
                  onChange={(e) => setArrival(e.target.value)}
                />
              </div>
              <div className="flex-1">
                <input
                  type="date"
                  required
                  className="!text-sm mr-2 w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-[1.5px] focus:ring-green-600 h-[50px]"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div class="flex-none ml-2">
                <div
                  onClick={() => setSearchBox(searchBox - 1)}
                  class="px-2 py-2 bg-red-700 !text-[15px] md:text-base text-white rounded h-[50px]"
                >
                  Delete
                </div>
              </div>
            </div>
          ))}

        <div className="my-4">
          <div
            onClick={() => setSearchBox(searchBox + 1)}
            className="bg-green-700 duration-300 text-white !text-[15px] md:text-base px-4 py-2 rounded hover:bg-green-900 ms-auto flex"
          >
            Add More
          </div>
        </div>
        <button className="bg-[#00703E] hover:bg-[#185f3f] text-[15px] duration-300 w-[150px] h-[50px] text-white rounded">
          Search Flights
        </button>
      </form>

      <div className="md:hidden">
        <form className="rounded">
          <div className="flex flex-wrap mb-4 relative gap-x-1">
            <div className="flex-1 relative mb-0 sm:mb-0">
              <input
                type="text"
                required
                className="mr-2 w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600"
                placeholder="Departure"
              />
            </div>
            <div className="flex-1 relative mb-2sm:mb-0">
              <input
                type="text"
                required
                className="mr-2 w-full p-2 h-[40px] border text-sm rounded focus:outline-none focus:ring-1 focus:ring-green-600"
                placeholder="Arrival"
              />
            </div>
            <div className="flex-1">
              <input
                type="date"
                required
                className="mr-2 w-full p-2 h-[40px] border rounded focus:outline-none date-input !text-sm focus:ring-1 focus:ring-green-600"
              />
            </div>
          </div>
          <div className="my-4">
            <button className="bg-green-700 text-white text-sm md:text-base px-4 py-2 rounded hover:bg-green-900 ms-auto flex">
              Add More
            </button>
          </div>
          <button
            type="submit"
            className="bg-green-800 w-full md:w-[150px] h-[40px] text-sm text-white rounded mt-2 md:mt-0"
          >
            Search Flight
          </button>
        </form>
      </div>
    </div>
  );
};

export default Multicity;
