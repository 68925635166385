import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  baggage: null,
  meal: null,
  flightClass: null,
};

const passengerServiceSlice = createSlice({
  name: "passegnerService",
  initialState,
  reducers: {
    setBaggageInfo: (state, action) => {
      state.baggage = action.payload;
    },
    setMeal: (state, action) => {
      state.meal = action.payload;
    },
    setflightClass: (state, action) => {
      state.flightClass = action.payload;
    }
  },
});

export const { setBaggageInfo, setMeal, setflightClass } =
passengerServiceSlice.actions;

export default passengerServiceSlice.reducer;