// redux/travelerSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  travelerCounts: {
    adults: 1,
    children: 0,
    kids: 0,
    infants: 0,
  },
};

const travelerSlice = createSlice({
  name: "traveler",
  initialState,
  reducers: {
    setTravelerCounts: (state, action) => {
      state.travelerCounts = action.payload;
    },
    setUpdateTravelerCount: (state, action) => {
      const { type, count } = action.payload;
      state.travelerCounts[type] = count;
    },
  },
});

export const { setTravelerCounts, setUpdateTravelerCount } =
  travelerSlice.actions;
export default travelerSlice.reducer;
