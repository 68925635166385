"use client";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";

const PopularDestinations = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section className="popular-destinations py-12 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-left text-primary mb-8 text-2xl font-bold">
          Popular Destinations for Business Class Deals
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
          {[
            {
              path: "https://hamsfly.com/",
              label: "New York to London",
              text: "Fly in style and arrive refreshed for your meeting or vacation.",
              icon: "🌍",
            },
            {
              path: "https://hamsfly.com/",
              label: "Los Angeles to Tokyo",
              text: "Enjoy a seamless journey with premium service on long-haul flights.",
              icon: "✈️",
            },
            {
              path: "https://hamsfly.com/",
              label: "Dubai to Sydney",
              text: "Make your trip unforgettable with luxury travel at a fraction of the cost.",
              icon: "🛫",
            },
            {
              path: "https://hamsfly.com/",
              label: "Riyadh to New York",
              text: "Fly with onboard airlines privilege.",
              icon: "🌟",
            },
          ].map((item, index) => (
            <motion.a
              href={item.path || "#"}
              target="_blank"
              key={index}
              className="p-6 bg-white shadow-md rounded-lg flex items-start space-x-4 hover:shadow-xl transition-shadow"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={cardVariants}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <motion.div
                className="text-4xl"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                {item.icon}
              </motion.div>
              <div>
                <motion.h3
                  className="text-lg font-semibold text-gray-800"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.2 }}
                >
                  {item.label}
                </motion.h3>
                <motion.p
                  className="text-gray-600 mt-2"
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.4, delay: index * 0.3 }}
                >
                  {item.text}
                </motion.p>
              </div>
            </motion.a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularDestinations;
